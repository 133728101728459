@use '../../../styles/varibales' as v;

.trashbin-ag-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100vw - 80px);
  padding: 20px;
  background-color: #f9f9f9;
}

.trashbin-ag-title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  color: v.$secondary;
  margin-bottom: 20px;
  margin-left: 10px;
  text-align: left;

  i.material-icons {
    position: relative;
    top: 4px;
    font-size: 25px;
  }
}

.trashbin-ag-toolbar {
  display: flex;
  justify-content: right;
  background-color: rgba(0, 0, 0, 0.1);
  width: max-content;
  margin-left: auto;
  margin-bottom: 10px;
  padding: 5px 8px;
  border-radius: 10px;
  margin-top: -50px;
}

.trashbin-ag-toolbar span {
  font-size: 13px;
  color: black;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

.trashbin-loading,
.trashbin-no-assets {
  font-size: 18px;
  color: v.$secondary;
  text-align: center;
  padding: 20px;
}

.trashbin-ag-toolbar-button {
  background-color: v.$secondary;
  color: white;
  border: none;
  padding: 5px 8px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 1px v.$secondary;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.15s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: v.$secondary;
    background-color: white;
  }

  &:disabled {
    background-color: white;
    color: v.$secondary;
    border: solid 1px v.$secondary;
    cursor: not-allowed;
  }
}

.trashbin-ag-toolbar-button-icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;

  i.material-icons {
    font-size: 16px;
  }
}

.trashbin-ag-toolbar-button-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.trashbin-ag-dropdown-content-row-button {
  background-color: white !important;
  width: calc(100% - 10px);
  display: flex;
  align-items: center;
  padding: 5px 8px;
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
  color: v.$secondary;
  font-size: 14px;
  transition: ease-in-out 0.15s;
  border: none;

  i.material-icons {
    font-size: 14px;
    /* Updated icon size */
    margin-right: 8px;
    margin-top: 2px;
  }

  &:hover {
    background-color: v.$secondary !important;
    color: white;
  }

  &:disabled {
    background-color: white !important;
    color: v.$secondary-lightish;
    cursor: not-allowed;
  }
}

.trashbin-ag-dropdown-content-row {
  background-color: white !important;
  display: flex;
  align-items: center;
  padding: 5px 8px;
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
  color: v.$secondary;
  font-size: 14px;
  transition: ease-in-out 0.15s;
  border: none;

  i.material-icons {
    font-size: 14px;
    /* Updated icon size */
    margin-right: 8px;
    margin-top: 2px;
  }

  &:hover {
    background-color: v.$secondary !important;
    color: white;
  }
}

.trashbin-ag-dropdown-menu-button {
  background-color: v.$secondary;
  border: none;
  padding: 8px;
  border-radius: 4px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  transition: ease-in-out 0.15s;

  &:hover {
    color: v.$secondary;
    background-color: white;
  }

  &:disabled {
    cursor: not-allowed;
    color: white;

    &:hover {
      color: v.$secondary;
      background-color: white;
    }
  }

  i.material-icons {
    font-size: 15px;
    width: 10px;
    margin-top: -1px;
    height: 10px;
    padding-bottom: 4px;
    margin-right: 5px;
    color: inherit;
  }
}

.trashbin-ag-dropdown-content {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.5);
  margin-top: 7px;
  z-index: 1000;
  right: 40px;
  border-radius: 10px !important;
}

[class^='ag-'],
[class^='ag-']:focus,
[class^='ag-']:after,
[class^='ag-']:before {
  box-sizing: border-box;
  outline: none;
  color: inherit;
}

.trashbin-ag-breadcrumb {
  padding: 10px;
  font-size: 16px;
  background-color: v.$secondary-white;
  border: solid 1px v.$secondary-light;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: left;
  width: max-content;
}

.trashbin-ag-breadcrumb-item {
  cursor: pointer;
  color: v.$secondary;
  text-decoration: none;
  transition: ease-in-out 0.15s;

  &:hover {
    text-decoration: underline;
  }
}

.trashbin-ag-type-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trashbin-ag-row-name {
  white-space: normal;
  line-height: 25px;
  text-align: left;
}

.trashbin-ag-category-cell {
  text-align: center;
  background-color: v.$secondary;
  color: white !important;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: center !important;
  font-size: 13px;
  align-items: center !important;
  height: 30px;
  width: 100px !important;
  top: 50%;
  bottom: 50%;
  left: 10px;
}

.ag-body {
  max-height: 100%; // Ensure the body respects the height of the container
}

.ag-unselectable {
  width: max-content !important;
  background-color: v.$secondary;
  color: white;
}

.ag-theme-alpine {
  overflow: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none;
  outline: none;
}

.ag-theme-alpine .ag-header {
  background-color: v.$secondary;
  color: v.$secondary;
}

.ag-center-cols-container {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
  height: 100%;
}

.ag-center-cols-container::-webkit-scrollbar {
  width: 0px; /* Set scrollbar width */
  height: 0px;
}

.ag-theme-alpine .ag-header-cell {
  text-align: center;
  color: white;
}
[class^='ag-'],
[class^='ag-']:focus,
[class^='ag-']:after,
[class^='ag-']:before {
  box-sizing: border-box;
  outline: none;
}
.ag-theme-alpine .ag-cell {
  padding: 10px;
  color: black;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.custom-row-class {
  background-color: white;
  cursor: default;
  border-radius: 10px;
  margin-left: 5px;
  width: calc(100% - 10px);
  position: relative;
  transform: translateY(0) !important;
  border: solid 1px v.$secondary-light;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.ag-theme-alpine .ag-row-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.ag-theme-alpine .ag-checkbox-input {
  margin-right: 10px;
  background-color: v.$secondary;
  cursor: pointer;
}

.ag-root-wrapper {
  border: none;
}

.trashbin-trash-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
  padding: 20px;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.trashbin-trash-popup-content {
  display: flex;
  flex-direction: column;
}

.trashbin-trash-popup-title {
  font-size: 18px;
  font-weight: 500;
  color: v.$secondary;
  margin-bottom: 20px;
}

.trashbin-trash-popup-files {
  margin-bottom: 20px;
}

.trashbin-trash-popup-files-title {
  font-size: 16px;
  font-weight: 500;
  color: v.$secondary;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 5px;
}

.trashbin-trash-popup-file-list {
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.trashbin-trash-popup-file-item {
  margin-bottom: 5px;
  font-size: 14px;
  color: black;
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 5px;
  text-align: left;
  border: solid 1px rgba(0, 0, 0, 0.3);
}

.trashbin-trash-popup-confirm-title {
  font-size: 16px;
  font-weight: 500;
  color: v.$secondary;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 5px;
}

.trashbin-trash-popup-confirm {
  margin-bottom: 20px;
}

.trashbin-trash-popup-input {
  width: calc(100% - 20px);
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.trashbin-trash-popup-actions {
  display: flex;
  justify-content: space-between;
}

.trashbin-trash-popup-cancel-button,
.trashbin-trash-popup-confirm-button {
  background-color: v.$secondary;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.15s ease;

  &:hover {
    background-color: darken(v.$secondary, 10%);
  }

  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
}

.basepopup-trashbin-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  cursor: default;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
