@use '../../../styles/varibales' as v;

.aggrid-common-no-rows-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 13px;
  color: grey;
  border-radius: 5px;
  padding: 10px;
  font-style: italic;
}
