$black: #000;
$white: #fff;
$primary: #fedc62;
$primary-dark: #ffc800;
$secondary: #003580;
$secondary-lightish: #bdd8ff;
$secondary-light: #d6e7ff;
$secondary-white: #f2f8ff;
$header-color: #4976b59f;
$white-color: #fff;
$disabled-background-color: #696969;
$error: rgb(236, 0, 0);
$success: #4caf50;
