@use '../../../styles/varibales' as v;

.search-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  height: 100vh;
}

.search-page-title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  color: v.$secondary;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: auto;
  text-align: left;

  i.material-icons {
    position: relative;
    top: 4px;
    font-size: 25px;
  }

  p {
    color: rgb(241, 35, 35);
  }
}

.search-page-ag-toolbar {
  display: flex;
  justify-content: right;
  background-color: rgba(0, 0, 0, 0.1);
  width: max-content;
  margin-left: auto;
  margin-bottom: 10px;
  padding: 5px 8px;
  border-radius: 10px;
  right: 20px;
  position: absolute;
}

.search-page-ag-toolbar span {
  font-size: 13px;
  color: black;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

.search-page-loading,
.search-page-no-assets {
  font-size: 18px;
  color: v.$secondary;
  text-align: center;
  padding: 20px;
}

.search-page-ag-toolbar-button {
  background-color: v.$secondary;
  color: white;
  border: none;
  padding: 5px 8px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 1px v.$secondary;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.15s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: v.$secondary;
    background-color: white;
  }

  &:disabled {
    background-color: white;
    color: v.$secondary;
    border: solid 1px v.$secondary;
    cursor: not-allowed;
  }
}

.search-page-ag-toolbar-button-icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;

  i.material-icons {
    font-size: 16px;
  }
}

.search-page-ag-toolbar-button-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-page-ag-toolbar-button-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-page-ag-dropdown-content-row {
  background-color: white !important;
  display: flex;
  align-items: center;
  padding: 5px 8px;
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
  color: v.$secondary;
  font-size: 14px;
  transition: ease-in-out 0.15s;

  i.material-icons {
    font-size: 14px;
    /* Updated icon size */
    margin-right: 8px;
    margin-top: 2px;
  }

  &:disabled {
    background-color: red;
    color: v.$secondary;
    border: solid 1px v.$secondary;
    cursor: not-allowed;
  }

  &:hover {
    background-color: v.$secondary !important;
    color: white;
  }
}

.search-page-ag-dropdown-menu-button {
  background-color: v.$secondary;
  border: none;
  padding: 8px;
  border-radius: 4px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  transition: ease-in-out 0.15s;

  &:hover {
    color: v.$secondary;
    background-color: white;
  }

  &:disabled {
    cursor: not-allowed;
    color: white;

    &:hover {
      color: v.$secondary;
      background-color: white;
    }
  }

  i.material-icons {
    font-size: 15px;
    width: 10px;
    margin-top: -1px;
    height: 10px;
    padding-bottom: 4px;
    margin-right: 5px;
    color: inherit;
  }
}

.search-page-ag-dropdown-content {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.5);
  margin-top: 7px;
  z-index: 1000;
  right: 8px;
  border-radius: 10px !important;
}

.search-page-table-and-filters-container {
  display: flex;
  flex-direction: row;
  width: calc(100vw - 77px);
}

.search-page-filters-container {
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  min-width: 250px;
  border-radius: 10px;
  margin-right: 10px;
  height: calc(100vh - 190px);
  overflow-x: hidden;
  overflow-y: auto;
}

.search-page-filters-title {
  border-radius: 10px;
  margin-top: calc(100vh - 850px);
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  color: grey;
  font-style: italic;
  font-size: 13px;
  padding: 10px;
}

.search-page-ag-row-name {
  white-space: normal;
  line-height: 25px;
  text-align: left;
}

.search-page-ag-row-snippet {
  white-space: normal;
  line-height: 15px;
  text-align: left;
  font-size: 11px;
  padding: 5px;
  background-color: white;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  width: calc(100% - 30px);
  border-radius: 10px;
  overflow-y: auto !important;
  overflow-x: hidden;
  height: 100%;
}

.search-page-ag-row-snippet::-webkit-scrollbar {
  width: 12px;
  /* Set scrollbar width */
  display: block;
}

.search-page-ag-row-snippet::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 3px;
}

.search-page-ag-row-snippet::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 3px;
}

.search-page-ag-row-snippet::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}

.search-page-ag-row-snippet::-webkit-scrollbar-track {
  background: transparent;
  /* Track background */
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none;
  outline: none;
}

.ag-theme-alpine {
  width: 100%;
  border-radius: 10px;
  overflow: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none;
  outline: none;
}

.ag-theme-alpine .ag-header {
  background-color: white;
  color: v.$secondary;
}

.ag-center-cols-container {
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 10px;
  height: 100% !important;
}

.ag-center-cols-viewport {
  background-color: white;
}

.ag-side-buttons {
  background-color: v.$secondary;
  color: white !important;
}

.ag-theme-alpine .ag-header-cell {
  text-align: center;
  color: black;
}

[class^='ag-'],
[class^='ag-']:focus,
[class^='ag-']:after,
[class^='ag-']:before {
  box-sizing: border-box;
  outline: none;
}

.ag-checkbox .ag-input-wrapper,
.ag-radio-button .ag-input-wrapper {
  overflow: visible;
  background: none;
}

.ag-theme-alpine .ag-cell {
  padding: 10px;
  color: black;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.ag-theme-alpine .ag-row-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.ag-theme-alpine .ag-checkbox-input {
  margin-right: 10px;
  background-color: v.$secondary;
  cursor: pointer;
}

.ag-center-cols-container {
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 10px;
  height: 100% !important;
}

.custom-row-class-search-page {
  background-color: white;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 5px;
  width: calc(100% - 10px);
  position: relative;
  transform: translateY(0) !important;
  border: solid 1px v.$secondary-light;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.search-page-bar-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.search-page-searchbar {
  width: 100%;
  padding: 12px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 18px;
  height: 20px;
  text-align: left;

  &::placeholder {
    text-align: left;
  }

  &:disabled {
    background-color: lightgray;
    pointer-events: none;
    opacity: 0.5;
  }
}

.search-page-trash-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
  padding: 20px;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.search-page-trash-popup-content {
  display: flex;
  flex-direction: column;
}

.search-page-trash-popup-title {
  font-size: 18px;
  font-weight: 500;
  color: v.$secondary;
  margin-bottom: 20px;
}

.search-page-trash-popup-files {
  margin-bottom: 20px;
}

.search-page-trash-popup-files-title {
  font-size: 16px;
  font-weight: 500;
  color: v.$secondary;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 5px;
}

.search-page-trash-popup-file-list {
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-page-trash-popup-file-item {
  margin-bottom: 5px;
  font-size: 14px;
  color: black;
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 5px;
  text-align: left;
  border: solid 1px rgba(0, 0, 0, 0.3);
}

.search-page-trash-popup-confirm-title {
  font-size: 16px;
  font-weight: 500;
  color: v.$secondary;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 5px;
}

.search-page-trash-popup-confirm {
  margin-bottom: 20px;
}

.search-page-trash-popup-input {
  width: calc(100% - 20px);
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-page-trash-popup-actions {
  display: flex;
  justify-content: space-between;
}

.search-page-trash-popup-cancel-button,
.search-page-trash-popup-confirm-button {
  background-color: v.$secondary;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.15s ease;

  &:hover {
    background-color: darken(v.$secondary, 10%);
  }

  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
}

.search-page-dropdown-bar {
  display: flex;
  gap: 20px;
  background-color: v.$secondary;
  padding: 10px;
  color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  width: calc(100% - 30px);

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.search-page-filter-close-button {
  background-color: white;
  color: v.$secondary;
  border: v.$secondary solid 1px;
  padding: 1px 5px 5px 5px;
  cursor: pointer;
  border-radius: 5px;
  height: 20px;
  width: 20px;
  transition: ease-in-out 0.1s;

  &:hover {
    color: white;
    background-color: v.$secondary;
  }
}

.search-page-dropdown-button {
  background-color: v.$secondary;
  border: none;
  color: white;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.search-page-dropdown-wrapper {
  position: relative;
  display: inline-block;

  .disabled {
    background-color: red;
  }
}

.search-page-terms-dropdown {
  position: absolute;
  background-color: white;
  color: v.$secondary;
  border: 1px solid #ccc;
  margin-top: 10px;
  z-index: 10;
  display: block;
  width: max-content;
  justify-content: space-between;
  border-radius: 0px 0px 5px 5px;
}

.search-page-excludes-label {
  border-top: solid 1px lightgray;
  width: 100%;
  font-size: 14px;
  color: v.$secondary;
  font-weight: bold;
  margin-top: 10px;
}

.search-page-excludes-label-text {
  margin-top: 5px;
}

.search-page-picklist-label {
  display: flex;
  flex-direction: row;
}

.search-page-terms-dropdown-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  cursor: default;
  text-align: left;
}

.search-page-terms-dropdown-item:hover {
  background-color: v.$secondary;
  color: white;
}

.search-page-term-label {
  flex-grow: 1;
}

.search-page-term-triage {
  flex-shrink: 0;
  margin-left: 10px;
}

.ag-unselectable {
  width: max-content !important;
  background-color: v.$secondary;
  color: white;
}

.search-page-sub-dropdown {
  position: absolute;
  color: v.$secondary;
  width: max-content;
  top: 0;
  left: 100%;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 11;
  border-radius: 0px 5px 5px 5px;
}

.search-page-sub-dropdown-item {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.search-page-sub-dropdown-item:hover {
  background-color: v.$secondary;
  color: white;
}

.search-page-filter-panel {
  margin-top: 20px;
}

.search-page-keyword-input-filter {
  max-width: 100px;
}

.search-page-filter-box-main {
  margin: 10px;

  input {
    border: solid 1px v.$secondary;
  }
}

.search-page-filter-box-sub {
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 10px;

  label {
    font-size: 14px;
    color: v.$secondary;
    font-weight: bold;
  }
}

.search-page-filter-title {
  font-weight: bold;
  font-size: 12px;
  display: flex;
  text-align: left;
  justify-content: space-between;
  color: v.$secondary;
  border-bottom: solid 1px lightgray;
  padding-bottom: 5px;
}

.search-page-filter-title-text {
  background-color: v.$secondary;
  color: white;
  width: 100%;
  margin-right: 5px;
  padding-left: 5px;
  padding-top: 2px;
  border-radius: 5px;
}

.search-page-filter-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 4px;
  }

  button:hover {
    background-color: #0056b3;
  }
}

.search-page-filter-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.search-page-toggle-label {
  font-weight: bold;
  margin: 0 5px;
  color: v.$secondary;
}

.search-page-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
  margin: 0 5px;
}

.search-page-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.search-page-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: v.$secondary;
  border-radius: 5px;
  cursor: pointer;
  border: solid 1px rgba(0, 0, 0, 0.5);
  transition: 0.4s;
}

.search-page-switch input:checked + .search-page-slider {
  background-color: v.$secondary-lightish;
}

.search-page-slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  border: solid 1px black;
  border-radius: 5px;
  transition: 0.4s;
}

.search-page-switch input:checked + .search-page-slider:before {
  transform: translateX(24px);
}

.search-page-from-to-container-main {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  label {
    font-size: 12px;
    color: v.$secondary;
    font-weight: bold;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  input {
    max-width: auto;
  }
}

.search-page-from-to-container-sub {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
