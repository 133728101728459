.loader-container {
  position: fixed;
  height: 101%;
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.loader-background {
  display: flex;
  height: 100%;
  width: 100%;
}

.column {
  flex: 1;
}

.column.blue {
  background-color: #003580;
}

.loader-yp-slow {
  position: absolute;
  width: 80px;
  height: 80px;
  margin-bottom: -15px;
  background: url('./yplogo.png') no-repeat center center;
  background-size: contain;
  animation: animLoaderSlow 1.5s infinite;
}

@-webkit-keyframes animLoaderSlow {
  from {
    -webkit-transform: rotateY(0deg);
  }

  to {
    -webkit-transform: rotateY(360deg);
  }
}

@keyframes animLoaderSlow {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.loader-yp-fast {
  position: absolute;
  width: 80px;
  height: 80px;
  margin-bottom: -15px;
  background: url('./yplogo.png') no-repeat center center;
  background-size: contain;
  animation: animLoaderSlow 0.8s infinite;
}

@-webkit-keyframes animLoaderSlow {
  from {
    -webkit-transform: rotateY(0deg);
  }

  to {
    -webkit-transform: rotateY(360deg);
  }
}

@keyframes animLoaderSlow {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.loader-container p {
  position: absolute;
  color: white;
  font-size: 20px;
  margin-top: 200px;
  letter-spacing: 2px;
  font-weight: 450;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3) 25%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.3) 75%
  );
  background-size: 200% auto;
  -webkit-background-clip: text;
  color: rgba(255, 255, 255, 0.4);
  animation: shimmer 2s linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% center;
  }

  100% {
    background-position: -200% center;
  }
}
